.a-page-push {
  @extend .ln-u-push-ends;
}

@media only screen and (max-width: 720px) {
  .a-page-push {
    @extend .ln-u-push-ends;
    padding-top: 40px;
  }

}

.a-no-link-style,
.a-no-link-style:hover,
.a-no-link-style:active,
.a-no-link-style:visited {
  color: inherit;
  text-decoration: inherit;
}

@media only screen and (max-width: 600px) {
  .ln-o-container--xs.r-pageName {
    margin-top: 3rem !important;
  }

  button#a-back-button {
    margin-top: 2.5rem;
  }
}
