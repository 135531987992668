.fileinputlabel {
  cursor: pointer;
  .fileinput {
    visibility: hidden;
    overflow: hidden;
    position: absolute;

    width: 1px;
    height: 1px;
  }
}
