.ln-c-modal__body {
  margin: auto;
  font-size: larger;
}

.store__selector__button {
  font-size: 24px;
  padding: 15px 30px;
}

@media screen and (max-width: 720px) {
  .a-store-mobile {
    margin-bottom: 3em;
    margin-top: 3em;
  }
}