.ln-c-toggle-button {
    color: #F06C00 !important;
}

.ln-c-toggle-button.is-active {
    color: white !important;
    background-color: #F06C00 !important; // get correct colour scale here
  }

.fa-label-button {
    margin-bottom: 0;
}

.fa-warning-banner {
    border: 1px solid #d50000;
    border-radius: 10px;
    margin-left: 25px;
    margin-bottom: 25px;
    padding: 15px 5px;
    background-color: rgba(255, 0, 0, 0.1)
}

.fa-banner-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fa-banner-error-circle {
    color: #d50000;
  }

  .fa-information-banner {
    border: 1px solid #1669ba;
    border-radius: 10px;
    margin-left: 25px;
    margin-bottom: 25px;
    padding: 15px 5px;
    background-color: rgba(66, 143, 218, 0.1)
}

.fa-banner-information-circle {
    color: #1669ba;
  }

.fa-information-list {
    padding-left: 20px;
    list-style-type: disc;
  }
