.a-remove-image-button {
  position: absolute;
  top: 8%;
  left: 85%;
}

.a-uploaded-image {
  width: 100%;
}

.a-grid-image-upload {
  position: relative;
  height: width;
}

.a-image-container {
  background-color: #f2f2f2;
  padding: 1rem;
  margin: 0 auto;
}
